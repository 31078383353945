<template>
  <el-card style="min-height: 45rem">
    <el-table :data="list">
      <el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="level" :formatter="typeFormatter" label="奖池"></el-table-column>
      <el-table-column prop="period" :formatter="periodFormatter" label="结算月" show-overflow-tooltip></el-table-column>
      <el-table-column prop="fee" label="分红总额" show-overflow-tooltip></el-table-column>
      <el-table-column prop="suc_num" label="达标人数" :formatter="sucFormatter" show-overflow-tooltip></el-table-column>
      <el-table-column prop="close_time" label="分红日期" :formatter="closeTimeFormatter" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" show-overflow-tooltip>
        <template v-slot="s">
          <el-button v-if="s.row.close" @click="see(s.row)">查看分红详情</el-button>
          <span v-else>结算后可查看详情列表</span>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
    <el-dialog destroy-on-close title="分红详情" :visible.sync="detailBox" @close="detailBoxClose">
      <el-table :data="detail_list">
        <el-table-column prop="id" label="记录编号"></el-table-column>
        <el-table-column prop="uid" label="用户编号"></el-table-column>
        <el-table-column prop="user_dealer_level" label="用户星级"></el-table-column>
        <el-table-column prop="total_sales_fee" label="业绩"></el-table-column>
        <el-table-column prop="assess" label="考核"></el-table-column>
        <el-table-column prop="award" label="分红金额"></el-table-column>
      </el-table>
    </el-dialog>
  </el-card>
</template>

<script>

export default {
  components: {},
  name: "manage",
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      detailBox:false,
      detail_list:[],
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    detailBoxClose(){
      this.detail_list = [];
    },
    see({id}){
      this.$u.api.shop.plugin.dealer.poolCloseLog({id}).then(res=>{
        this.detail_list = res;
        this.detailBox = true;
      })
    },
    sucFormatter(e){
      if(!e.close)return "未结算";
      return e.suc_num;
    },
    closeTimeFormatter(e){
      if(!e.close)return "未结算";
      return this.$u.formatDate(e.close_time);
    },
    typeFormatter(e) {
      let str = "";
      switch (parseInt(e.level)) {
        case 1:
          str = "一星";
          break;
        case 2:
          str = "二星";
          break;
        case 3:
          str = "三星";
          break
      }
      return str;
    },
    periodFormatter(e) {
      let value = e.period;
      if (!value) return "";
      let date = new Date(value);
      if (date.getTime() < 0) return "";
      const YY = date.getFullYear() + '年';
      const MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + "月";
      return YY + MM;
    },
    pageChange(e) {
      this.page = e;
      this.load();
    },
    load() {
      this.$u.api.shop.plugin.dealer.poolSearch({page: this.page}).then(res => {
        this.total = res.total;
        this.list = res.list;
      })
    },
  },
}
</script>

<style scoped>

</style>